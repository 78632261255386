import React, { FC } from 'react'
import dayjs from 'dayjs'

import { capitalize } from '../../helpers/helpers'
import Box from '../box'
import Text from '../text'
import { BuddyAction } from '../../models/buddy'
import { ButtonLink } from '../button'

interface Props {
  action: BuddyAction
  index: number
  onDelete(index: number): void
}

const BuddyHistoryItem: FC<Props> = ({ action, index, onDelete }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    py={3}
    borderTop="1px solid grey"
    borderTopColor="grey.2"
  >
    <Box flex="3" pl={3}>
      <Text fontSize={3} m={0} fontWeight="bold">
        {capitalize(action.type)}
      </Text>
      <Text fontSize={2} m={0} color="grey.5">
        {dayjs(action.timestamp).format('h:mma')}
      </Text>
      <Text fontSize={2} m={0} color="grey.5">
        Ready at: {dayjs(action.timestamp).add(30, 'm').format('h:mma')}
      </Text>
    </Box>
    <ButtonLink color="danger" flex="1" onClick={() => onDelete(index)}>
      Delete
    </ButtonLink>
  </Box>
)

export default BuddyHistoryItem
