import React, { FC, HTMLAttributes, useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import Button from '../button'
import Box from '../box'

import { BuddyAction, BuddyActionInfo, BuddyActionType } from '../../models/buddy'

const ActionButton = styled(Button)`
  padding: ${({ theme }) => theme.space[1]}px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`

interface Props extends HTMLAttributes<HTMLButtonElement> {
  uniqueActions: Record<string, BuddyAction>
  type: BuddyActionType
  actionInfo: BuddyActionInfo
}
const BuddyActionButton: FC<Props> = ({
  children,
  actionInfo: { points, cooldown },
  type,
  uniqueActions,
  ...rest
}) => {
  const [timeRemaining, setTimeRemaining] = useState(1)

  useEffect(() => {
    getTimeRemaining(type)
  }, [uniqueActions])

  const getTimeRemaining = (key: BuddyActionType) => {
    if (!uniqueActions[key] || cooldown === 0) {
      setTimeRemaining(1)
      return
    }

    const diff = Math.min(dayjs().diff(dayjs(uniqueActions[key].timestamp), 'minute'), cooldown)
    setTimeRemaining(diff / cooldown)
  }

  return (
    <ActionButton
      bg={timeRemaining === 1 ? 'white' : 'grey.1'}
      borderColor={timeRemaining === 1 ? 'black' : 'grey.2'}
      color={timeRemaining === 1 ? 'black' : 'grey.5'}
      position="relative"
      display="flex"
      alignItems="center"
      {...rest}
    >
      <>
        <Box
          position="absolute"
          bg={timeRemaining === 1 ? 'transparent' : 'grey.2'}
          top="0"
          left="0"
          right="0"
          bottom="0"
          style={{
            transform: `scaleX(${timeRemaining})`,
            transformOrigin: 'left center',
          }}
          zIndex="0"
        ></Box>
        <Box py={4} px={2} zIndex="1" textAlign="left">
          {children}
        </Box>
        <Box py={4} px={2} zIndex="1">
          {points}pts
        </Box>
      </>
    </ActionButton>
  )
}

export default BuddyActionButton
