import emotionTired from '../images/buddy/buddy_emotion_tired.png'
import emotionDull from '../images/buddy/buddy_emotion_dull.png'
import emotionNormal from '../images/buddy/buddy_emotion_normal.png'
import emotionHappy from '../images/buddy/buddy_emotion_happy.png'
import emotionSmile from '../images/buddy/buddy_emotion_smile.png'
import emotionFun from '../images/buddy/buddy_emotion_fun.png'
import emotionExcited from '../images/buddy/buddy_emotion_excited.png'

export enum BuddyActionType {
  Walk = 'walk',
  Treat = 'treat',
  Play = 'play',
  Battle = 'battle',
  Snapshot = 'snapshot',
  Visit = 'visit',
  Present = 'present',
  VisitSpecial = 'visitSpecial',
}

export enum BuddyLevel {
  Tired,
  Dull,
  Normal,
  Happy,
  Smile,
  Fun,
  Excited,
}

export interface BuddyAction {
  timestamp: Date
  type: BuddyActionType
  effective?: boolean
  isReady?: boolean
}

export interface BuddyStatus {
  value: number
  max: number
}

export interface BuddyActionInfo {
  points: number
  cooldown: number
  title: string
}

export const BUDDY_ACTION_INFO: Record<BuddyActionType, BuddyActionInfo> = {
  walk: {
    points: 3,
    cooldown: 0,
    title: 'Walk 2km',
  },
  treat: {
    points: 1,
    cooldown: 30,
    title: 'Give a treat',
  },
  play: {
    points: 1,
    cooldown: 30,
    title: 'Play',
  },
  battle: {
    points: 1,
    cooldown: 30,
    title: 'Battle',
  },
  snapshot: {
    points: 1,
    cooldown: 30,
    title: 'Take a Snapshot',
  },
  visit: {
    points: 1,
    cooldown: 30,
    title: 'Visit Location',
  },
  present: {
    points: 3,
    cooldown: 30,
    title: 'Open Present',
  },
  visitSpecial: {
    points: 3,
    cooldown: 30,
    title: 'Visit Location Found',
  },
}

const pointsForBuddyAction = (type: BuddyActionType) => BUDDY_ACTION_INFO[type].points

export const EXCITEMENT_MAX = 32

export const calculateCurrentExcitement = (actions: Array<BuddyAction>) =>
  actions.reduce((total, action) => {
    return pointsForBuddyAction(action.type) + total
  }, 0)

export const getBuddyImage = (currentPoints: number) => {
  if (currentPoints >= EXCITEMENT_MAX) {
    return emotionExcited
  } else if (currentPoints > 14) {
    return emotionFun
  } else if (currentPoints >= 10) {
    return emotionSmile
  } else if (currentPoints >= 5) {
    return emotionHappy
  } else if (currentPoints >= 2) {
    return emotionDull
  } else if (currentPoints >= 0) {
    return emotionTired
  }
  return emotionNormal
}
