import React, { FC, useEffect, useState } from 'react'
import { PageProps } from 'gatsby'
import { openDB, deleteDB, wrap, unwrap } from 'idb'

import Heading from '../components/heading'
import SEO from '../components/seo'
import Wrapper from '../containers/wrapper'
import Box from '../components/box'
import Button from '../components/button'
import BuddyActionButton from '../components/buddy/buddy-action-button'

import useInterval from '../hooks/useInterval'
import {
  BuddyAction,
  BuddyActionType,
  BUDDY_ACTION_INFO,
  calculateCurrentExcitement,
  EXCITEMENT_MAX,
  getBuddyImage,
} from '../models/buddy'
import BuddyHistoryItem from '../components/buddy/buddy-history-item'

const DATABASE = 'pokedex-go'
const STORE = 'buddy-excitement'

interface Props extends PageProps {}

const BuddyExcitementPage: FC<Props> = () => {
  const [buddyActions, setBuddyActions] = useState<Array<BuddyAction>>([])
  const [uniqueActions, setUniqueActions] = useState<Record<string, BuddyAction>>({})
  const [points, setPoints] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const total = calculateCurrentExcitement(buddyActions)
    setPoints(total)
  }, [buddyActions])

  useEffect(() => {
    openDatabase()
      .then((initialActions) => {
        setBuddyActions(initialActions)
        calculateUniqueActions(initialActions)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useInterval(() => {
    calculateUniqueActions()
  }, 1000 * 30)

  const openDatabase = async () => {
    const db = await openDB(DATABASE, 1, {
      upgrade(db) {
        db.createObjectStore(STORE)
      },
    })
    const value = (await db.get(STORE, 1)) || []
    return value
  }

  const saveToDatabase = async (actions: Array<BuddyAction>) => {
    const db = await openDB(DATABASE, 1, {
      upgrade(db) {
        db.createObjectStore(STORE)
      },
    })
    await db.put(STORE, actions, 1)
  }

  const calculateUniqueActions = (actions = buddyActions) => {
    var unique: Record<string, BuddyAction> = {}
    for (let i = 0; i < actions.length; i++) {
      if (!unique[actions[i].type]) {
        unique[actions[i].type] = actions[i]
      }
    }
    setUniqueActions(unique)
  }

  const resetProgress = () => {
    setBuddyActions([])
    setUniqueActions({})
    saveToDatabase([])
  }

  const addAction = (type: BuddyActionType) => {
    const copy = Array.from(buddyActions)
    copy.unshift({ timestamp: new Date(), type })
    setBuddyActions(copy)
    calculateUniqueActions(copy)
    saveToDatabase(copy)
  }

  const removeActionAt = (index: number) => {
    const copy = Array.from(buddyActions)
    copy.splice(index, 1)
    setBuddyActions(copy)
    calculateUniqueActions(copy)
    saveToDatabase(copy)
  }

  return (
    <Wrapper>
      <SEO title={`Buddy Excitement`} />
      <Heading as="h1" fontSize={8} mb={4}>
        Buddy Excitement
      </Heading>

      {!loading && (
        <>
          <Box display="flex" alignItems="center" mb={5}>
            <img src={getBuddyImage(points)} width="60px" />
            <Heading as="h3" fontSize={4} m={0} ml={4}>
              Total Points: {points} / {EXCITEMENT_MAX}
            </Heading>
          </Box>

          <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={3}>
            {Object.entries(BUDDY_ACTION_INFO).map(([key, item]) => (
              <BuddyActionButton
                key={key}
                onClick={() => addAction(key as BuddyActionType)}
                type={key as BuddyActionType}
                actionInfo={item}
                uniqueActions={uniqueActions}
              >
                {item.title}
              </BuddyActionButton>
            ))}
          </Box>
          <Box my={3}>
            <Button variant="primary" onClick={() => resetProgress()}>
              Reset
            </Button>
          </Box>

          <Box>
            <Heading as="h3" fontSize={4} mt={7} mb={3}>
              History
            </Heading>
            {buddyActions.map((action, index) => (
              <BuddyHistoryItem
                key={`buddy-history-item-${index}`}
                action={action}
                index={index}
                onDelete={removeActionAt}
              />
            ))}
          </Box>
        </>
      )}
    </Wrapper>
  )
}

export default BuddyExcitementPage
